<template>
  <b-modal
    id="hosting-login-info-modal"
    ref="hosting-login-info-modal"
    :title="title"
    centered
    hide-header-close
    size="md"
  >
    <div v-if="password">
      <p>Your admin username is: <span>{{ username }}</span></p>
      <p>Your admin password is: <span>{{ password }}</span></p>
    </div>
    <div v-else>
      <p>Uh oh, looks like something went wrong during your wordpress installation and we don't have your login credentials.</p>
      <p>Please contact customer support to resolve this issue.</p>
    </div>
    <template v-slot:modal-footer>
      <label class="text-secondary smaller-font">
        You can absolutely change this password, but if we need to login to help fix an issue, we may need to reset it.
      </label>
      <b-button
        variant="default"
        aria-label="Close button"
        @click="$emit('close')"
      >
        Close
      </b-button>

      <a :href="siteBuilderUrl" target="_blank" v-if="password">
        <b-button
          variant="primary"
          aria-label="SiteBuilder Login"
          @click="$emit('website-login-interaction')"
        >
          Login
        </b-button>
      </a>
    </template>
  </b-modal>
</template>

<script>

export default {
  name: 'HostingLoginInfoModal',
  props: {
    hostingInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      title: 'SiteBuilder Login Info',
    }
  },
  computed: {
    siteBuilderUrl() {
      return this.hostingInfo?.sitebuilder_url ?
        new URL(this.hostingInfo?.sitebuilder_url) :
        ''
    },
    username() {
      return this.siteBuilderUrl ?
        this.siteBuilderUrl.searchParams.get("username") :
        ''
    },
    password() {
      return this.siteBuilderUrl ?
        this.siteBuilderUrl.searchParams.get("password") :
        ''
    },
  },
}
</script>

<style lang="scss" scoped>
.smaller-font {
  font-size: 0.75em;
}
</style>
