var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "hosting-login-info-modal",
      attrs: {
        id: "hosting-login-info-modal",
        title: _vm.title,
        centered: "",
        "hide-header-close": "",
        size: "md",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c("label", { staticClass: "text-secondary smaller-font" }, [
                _vm._v(
                  "\n      You can absolutely change this password, but if we need to login to help fix an issue, we may need to reset it.\n    "
                ),
              ]),
              _c(
                "b-button",
                {
                  attrs: { variant: "default", "aria-label": "Close button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_vm._v("\n      Close\n    ")]
              ),
              _vm.password
                ? _c(
                    "a",
                    { attrs: { href: _vm.siteBuilderUrl, target: "_blank" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "primary",
                            "aria-label": "SiteBuilder Login",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("website-login-interaction")
                            },
                          },
                        },
                        [_vm._v("\n        Login\n      ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm.password
        ? _c("div", [
            _c("p", [
              _vm._v("Your admin username is: "),
              _c("span", [_vm._v(_vm._s(_vm.username))]),
            ]),
            _c("p", [
              _vm._v("Your admin password is: "),
              _c("span", [_vm._v(_vm._s(_vm.password))]),
            ]),
          ])
        : _c("div", [
            _c("p", [
              _vm._v(
                "Uh oh, looks like something went wrong during your wordpress installation and we don't have your login credentials."
              ),
            ]),
            _c("p", [
              _vm._v("Please contact customer support to resolve this issue."),
            ]),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }